import React from "react"
import { Card, Button, Badge, Text, Icon, Box} from "@shopify/polaris";
import styled from "styled-components"
const GreyCard = styled.div`
  position: relative;
  .card-container {
    position: relative;
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #d8d8d8;
      border-radius: 0.8rem;
      z-index: 10;
      opacity: 0.4;
    }
  }
  .upsell-badge {
    text-align: center;
    margin-top: 20px;
    .Polaris-Badge {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
`
const OfferTypeEdit = styled.div`
    margin: auto;
    .Polaris-Icon {
      width: 50px;
      height: 50px;
    }
` 
const OfferSelectionCard = (props) => {
  const { title, content, image, url, type, icon, grey = false, loading = false, plusOnly = false, learnMore = false } = props
  return grey ? (
    <GreyCard>
      <div class="card-container">
        <div class="img-overlay"></div>
        <Card>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={image} width={"100%"} />
              </div>
              <div style={{ padding: "15px 0 0 0" }}>
                <Text variant="headingSm" as="h3">
                  <Text  variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
                </Text>
              </div>
              <p style={{ paddingBottom: "15px" }}>{content}</p>
            { plusOnly &&
              <div className="upsell-badge">
                <Badge tone="success">SHOPIFY PLUS</Badge>
              </div>
            }
        </Card>
        
      </div>
      
    </GreyCard>
  ) : (
    <Card>
          <div
            style={"Cart upsell" === type ? 
            {textAlign: "center",
            height: "135px",
            display: "flex",
            alignItems: "center",
            background: "rgba(242, 245, 254, 1)",
            borderRadius: "0.75rem",
            }
            :{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {"Cart upsell" === type ?
           <OfferTypeEdit>
            <Icon source={icon} tone="info"></Icon> 
           </OfferTypeEdit>
           :
            <img src={image} width={"100%"} />
            }
          </div>
          <div style={{ padding: "15px 0 0 0" }}>
            <Text variant="headingSm" as="h3">
              <Text  variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
            </Text>
          </div>
          <p style={{ paddingBottom: "15px" }}>{content}</p>
          <Box paddingBlockEnd="200">
            <Button onClick={() => url()} loading={loading ? true : false}>
              Select
            </Button>
         </Box>
    </Card>
  );
}

export default OfferSelectionCard
